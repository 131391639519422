import { Link } from "react-router-dom";
import { OrderData } from "../../../types";
import { HistoryContent } from "./HistoryContent";
import { Grid } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TextLengthFitSize } from "./TextLengthFitSize";

interface Props {
  orderData: OrderData[];
  filterStatusList: string[];
  filterStatusListNumber: number;
}

export const Card = ({
  orderData,
  filterStatusList,
  filterStatusListNumber,
}: Props) => {
  return (
    <>
      {orderData
        .filter((item) =>
          filterStatusList[filterStatusListNumber] === "all"
            ? true
            : item.isStatus === filterStatusList[filterStatusListNumber]
        )
        .map((e, i) => (
          <div key={i} className="my-[3%]">
            <Link to={`/order/${e.id}`}>
              <div className="mx-auto w-[95%] rounded-lg px-3 py-2 shadow-[0px_3px_6px_rgba(0,0,0,0.2)]">
                <div color="text.secondary">
                  <p className="text-end text-runticketGrayText">
                    {e.date.toDate().toLocaleString()}
                  </p>
                </div>
                <div className="flex justify-between">
                  <HistoryContent menu={e.menu} />
                  <h3 className="my-auto mr-2 text-[30px] text-runticketBlue">
                    ¥{e.totalPrice}
                  </h3>
                </div>
                <Grid container color="text.secondary">
                  {e.isStatus === "complete" && (
                    <>
                      <Grid
                        item
                        xs={0}
                        className="flex items-center text-indicatorGreen"
                      >
                        <TaskAltIcon className="text-xl" />
                      </Grid>
                      <Grid item xs={5}>
                        <p className="ml-1 text-[15px] text-indicatorGreen">
                          受取済み
                        </p>
                      </Grid>
                    </>
                  )}
                  {(e.isStatus === "ordered" || e.isStatus === "cooked") && (
                    <>
                      <Grid
                        item
                        xs={0}
                        className="flex items-center text-indicatorOrange"
                      >
                        <ErrorOutlineIcon className="text-xl" />
                      </Grid>
                      <Grid item xs={5} className="text-start">
                        <p className="ml-1 text-[15px] text-indicatorOrange">
                          未受取
                        </p>
                      </Grid>
                    </>
                  )}
                  {e.isStatus === "not_payed" && (
                    <>
                      <Grid
                        item
                        xs={0}
                        className="flex items-center text-indicatorRed"
                      >
                        <ErrorOutlineIcon className="text-xl" />
                      </Grid>
                      <Grid item xs={5} className="text-start">
                        <TextLengthFitSize
                          className="japanese_L ml-1 text-indicatorRed"
                          text={`確認してください`}
                          constant={90}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs className="my-auto text-end">
                    <TextLengthFitSize
                      className="japanese_R text-blue-400"
                      text={`ID: ${e.id}`}
                      constant={90}
                    />
                  </Grid>
                </Grid>
              </div>
            </Link>
          </div>
        ))}
    </>
  );
};
