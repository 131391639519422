import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LoadingAnimation } from "../components/LoadingAnimation";
import { RedirectToErrorPage } from "../api/helper";
import { paymentType } from "../types";
import { GetPayPayDetails, GetStripeDetails } from "../features/payment/api";
import { UpdateOrderAndReduceQuantity } from "../features/order/api";
export const GetPaymentStatus = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const checkoutId = params.id;
      const paymentType = params.paymentType as paymentType;
      try {
        if (checkoutId && paymentType === "paypay") {
          const paypayDetails = await GetPayPayDetails(checkoutId);
          if (paypayDetails.data.status === "COMPLETED") {
            await UpdateOrderAndReduceQuantity(checkoutId);
            navigate(`/order/${checkoutId}/success`);
          } else {
            navigate(`/order/${checkoutId}/failed`);
          }
        } else if (checkoutId && paymentType === "stripe") {
          const stripeDetails = await GetStripeDetails(checkoutId);
          if (
            stripeDetails.status === "complete" &&
            stripeDetails.client_reference_id
          ) {
            await UpdateOrderAndReduceQuantity(
              stripeDetails.client_reference_id
            );
            navigate(`/order/${stripeDetails.client_reference_id}/success`);
          } else {
            navigate(`/order/${stripeDetails.client_reference_id}/failed`);
          }
          console.log(stripeDetails);
        }
      } catch (e) {
        console.log(e);
        const error = e as string;
        if (error.includes("invalid-argument")) {
          navigate(`/order/${checkoutId}/failed`);
        } else {
          RedirectToErrorPage(e);
        }
      }
    })();
  }, [navigate, params.id, params.paymentType]);

  return (
    <>
      <div className="my-4">
        <h2 className="japanese_thin py-5 text-center text-2xl font-bold">
          決済を確認中です
        </h2>
        <LoadingAnimation type="orbit" />
      </div>
    </>
  );
};
