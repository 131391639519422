import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorPage } from "../pages/ErrorPage";
import { Menu } from "../pages/Menu";
import { OrderCompleted } from "../pages/OrderCompleted";
import { Register } from "../pages/Register";
import { HistoryPage } from "../pages/History";
import { Redirect } from "../components/Redirect";
import { Terms } from "../pages/Terms";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { Help } from "../pages/Help";
import { GetPaymentStatus } from "../pages/GetPaymentStatus";
import Admin from "../pages/Admin";
import { ScrollToTop } from "../components/ScrollTopPage";
import { ThemeProvider } from "@mui/material";
import { theme } from "../assets/theme";
import { TestData } from "../test/TestData";
import { Header } from "../features/header";
import Footer from "../features/footer";
import { useAuth } from "../features/auth/hooks";
import { SnackbarProvider } from "notistack";
import { CheckBox } from "../assets/icons/CheckBox";
import { AlertCircle } from "../assets/icons/AlertCircle";
import { SilverWare } from "../assets/icons/SilverWare";
import { CorrectEmail } from "../features/auth/utils";
import { Logout } from "../features/auth/components";
import { LoadingAnimation } from "../components/LoadingAnimation";

const Router = () => {
  const { user, loginState, login, logout, deleteAccount } = useAuth();
  const iconStyles = {
    color: "white",
    marginTop: "5px",
    marginRight: "3px",
    width: "20px",
  };
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        color="white"
        iconVariant={{
          success: <CheckBox style={iconStyles} />,
          error: <AlertCircle style={iconStyles} />,
          warning: <SilverWare style={iconStyles} />,
        }}
        maxSnack={3}
      >
        <BrowserRouter>
          <main>
            <Header photoURL={user?.photoURL ?? ""} />
            <Routes>
              {loginState === "login" && user && CorrectEmail(user.email) ? (
                <>
                  <Route path="/" element={<Menu />} />
                  <Route
                    path="/register"
                    element={
                      <Register
                        login={login}
                        user={user}
                        logout={logout}
                        deleteAccount={deleteAccount}
                      />
                    }
                  />
                  <Route path="/test" element={<TestData />} />
                  <Route path="/order/:id" element={<OrderCompleted />} />
                  <Route
                    path="/order/:id/:status"
                    element={<OrderCompleted />}
                  />
                  <Route
                    path="/check/:id/:paymentType"
                    element={<GetPaymentStatus />}
                  />
                  <Route path="/history" element={<HistoryPage />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route
                    path="/contact"
                    element={
                      <Redirect url="https://docs.google.com/forms/d/e/1FAIpQLSfRRIK0WBAoMt_WN3RAKbP598LZOQAhsOrIQu8O7eAZE81x1Q/viewform" />
                    }
                  />
                  <Route path="/help" element={<Help />} />
                  <Route path="/admin" element={<Admin />} />
                </>
              ) : (
                <>
                  <Route
                    path="/"
                    element={
                      loginState === "fetching" ? (
                        <LoadingAnimation type="jelly" />
                      ) : (
                        <Register
                          login={login}
                          user={user}
                          logout={logout}
                          deleteAccount={deleteAccount}
                        />
                      )
                    }
                  />
                </>
              )}
              <Route path="/logout" element={<Logout logout={logout} />} />
              <Route
                path="/error/:errorText/*"
                element={
                  <ErrorPage
                    text={"エラーが発生しました"}
                    onClick={() => {
                      window.location.href = "/";
                    }}
                    buttonText={"ホームに戻る"}
                  />
                }
              />
              <Route
                path="/maintenance"
                element={
                  <ErrorPage
                    text={"現在営業時間外です"}
                    onClick={() => {
                      window.location.href = "/";
                    }}
                    buttonText={"ホームに戻る"}
                    isMaintenance
                  />
                }
              />
              <Route
                path="*"
                element={
                  loginState === "fetching" ? (
                    <LoadingAnimation type="jelly" />
                  ) : (
                    <ErrorPage
                      text={"お探しのページは見つかりませんでした"}
                      onClick={() => {
                        window.location.href = "/";
                      }}
                      buttonText={"ホームに戻る"}
                    />
                  )
                }
              />
            </Routes>
            <ScrollToTop />
          </main>
        </BrowserRouter>
        <Footer />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Router;
