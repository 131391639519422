import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { QrCodeMock } from "../assets/icons/QrCodeMock";
import { Scan } from "../assets/icons/Scan";
import { Printer } from "../assets/icons/Printer";
import { Ticket } from "../assets/icons/Ticket";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Mousewheel, Keyboard } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "../assets/styles/Slides.css";

export default function Slide() {
  const Slides = [
    [
      "食券の受け取りには、画面に表示された二次元コードを利用します",
      <QrCodeMock key="0" className="h-60 md:h-96" />,
    ],
    [
      "21階に設置したタブレットでこの画面に表示された二次コードを読み取ります",
      <Scan key="1" className="h-60 -rotate-90 md:h-96" />,
    ],
    [
      "読み取りが完了すると、隣に設置してあるプリンターから食券が発行されます",
      <Printer key="2" className="h-60 rotate-[-10deg] md:h-96" />,
    ],
    [
      "食堂のスタッフへ食券を提示してください",
      <Ticket key="3" className="h-60 md:h-96" />,
    ],
  ];

  return (
    <Swiper
      spaceBetween={50}
      centeredSlides
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      rewind
      pagination={{
        clickable: true,
      }}
      mousewheel
      keyboard
      modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
    >
      {Slides.map((slide, index) => {
        return (
          <SwiperSlide key={index}>
            <p className="mx-[10%] text-black">
              <span className="text-lg font-bold text-black/80">
                {index + 1}.
              </span>
              {slide[0] as string}
            </p>
            <div className="mx-auto my-5 flex justify-center">{slide[1]}</div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
