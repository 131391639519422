import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties | undefined;
  className?: string;
}
export const QrCodeMock = ({ style, className }: Props) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="181.858"
      height="358.742"
      viewBox="0 0 181.858 358.742"
      className={className}
    >
      <defs></defs>
      <path d="M140.581,10.061v0c.064,3.084,4.79,3.1,4.8,0,0-.19,0-.379,0-.569h-4.8c0,2.7.338,5.558-.706,8.11a9.749,9.749,0,0,1-7.5,5.856,42.252,42.252,0,0,1-5.945.086l-6.574.009-14.238.021q-26.628.042-53.256-.035a12.087,12.087,0,0,1-5.794-1,9.772,9.772,0,0,1-4.005-3.757c-1.657-2.836-1.31-6.069-1.306-9.227h-4.8c0,.17,0,.34,0,.51.01,3.092,4.732,3.088,4.8,0v0c.068-3.09-4.732-3.089-4.8,0v0h4.8c0-.17,0-.34,0-.51,0-3.088-4.8-3.093-4.8,0,0,3.239-.24,6.548.994,9.616a14.6,14.6,0,0,0,9.241,8.558,22.087,22.087,0,0,0,6.545.616l6.755.009,15.194.022q13.887.023,27.775.006l15.279-.023,7.072-.01c2.172,0,4.386.116,6.553-.041a14.54,14.54,0,0,0,11.789-7.531c1.9-3.569,1.727-7.365,1.725-11.281,0-3.089-4.8-3.094-4.8,0,0,.19,0,.379,0,.569h4.8v0c-.064-3.084-4.864-3.095-4.8,0Z" />
      <path d="M149.982,353.942H32.924c-10.894,0-20.753-5.561-25.554-15.535-3.642-7.566-2.57-17.116-2.57-25.321V33.133c0-7.835,2.546-15.173,8.264-20.715a27.24,27.24,0,0,1,18.8-7.61c1.054-.008,2.109,0,3.163,0H143.012c5.695,0,11.234-.261,16.7,1.8,10.544,3.97,17.3,14.431,17.33,25.555.006,2.361,0,4.722,0,7.083V308.9c0,6.157.1,12.325,0,18.482a27.347,27.347,0,0,1-27.054,26.559c-3.087.026-3.095,4.826,0,4.8a32.213,32.213,0,0,0,31.6-27.944,64.356,64.356,0,0,0,.259-8.086V53.228c0-7.079.041-14.16,0-21.239A32.294,32.294,0,0,0,160.986,1.976C155.248-.188,149.351.008,143.364.008H34.663c-1.264,0-2.532-.026-3.795.016A32.135,32.135,0,0,0,10,8.705,32.609,32.609,0,0,0,0,32.793V324.851c0,6.252,1.041,12.315,4.2,17.834,5.9,10.3,16.863,16.057,28.579,16.057h117.2C153.07,358.742,153.076,353.942,149.982,353.942Z" />
      <path d="M59.391,344.307l21.815,0,32.262-.006,8.981,0c3.089,0,3.094-4.8,0-4.8q-11.031,0-22.063,0l-32.193.006-8.8,0c-3.089,0-3.094,4.8,0,4.8Z" />
      <path d="M72.589,15.866q11.6,0,23.2-.012c3.088,0,3.093-4.8,0-4.8q-11.6,0-23.2.012c-3.089,0-3.094,4.8,0,4.8Z" />
      <path d="M109.251,16.266c3.6,0,3.61-5.6,0-5.6s-3.609,5.6,0,5.6Z" />
      <path d="M78.845,79.884H43.668a2.433,2.433,0,0,0-2.4,2.4v35.177a2.434,2.434,0,0,0,2.4,2.4H78.845a2.433,2.433,0,0,0,2.4-2.4q0-17.587,0-35.177c0-3.089-4.8-3.094-4.8,0q0,17.589,0,35.177l2.4-2.4H43.668l2.4,2.4V82.284l-2.4,2.4H78.845C81.934,84.684,81.939,79.884,78.845,79.884Z" />
      <path d="M88.49,82.284v35.177c0,3.089,4.8,3.094,4.8,0V82.284c0-3.089-4.8-3.094-4.8,0Z" />
      <path d="M78.846,126.274H43.668c-3.089,0-3.094,4.8,0,4.8H78.846c3.088,0,3.093-4.8,0-4.8Z" />
      <path d="M136.935,126.274h-19.28c-3.089,0-3.094,4.8,0,4.8h19.28c3.088,0,3.093-4.8,0-4.8Z" />
      <path d="M90.356,131.074h17.068l-2.4-2.4v17.068a2.435,2.435,0,0,0,2.4,2.4h17.068l-2.4-2.4v17.1c0,3.089,4.8,3.094,4.8,0v-17.1a2.435,2.435,0,0,0-2.4-2.4H107.424l2.4,2.4V128.674a2.435,2.435,0,0,0-2.4-2.4H90.356c-3.088,0-3.093,4.8,0,4.8Z" />
      <path d="M88.55,140.2v35.178a2.435,2.435,0,0,0,2.4,2.4h11.623c3.088,0,3.093-4.8,0-4.8H90.95l2.4,2.4V140.2c0-3.089-4.8-3.094-4.8,0Z" />
      <path d="M113.6,177.775h23.334a2.435,2.435,0,0,0,2.4-2.4V150.758c0-3.089-4.8-3.094-4.8,0v24.617l2.4-2.4H113.6c-3.089,0-3.094,4.8,0,4.8Z" />
      <path d="M66.182,94.948h-9.85V104.8h9.85Z" />
      <path d="M66.182,92.548h-9.85a2.434,2.434,0,0,0-2.4,2.4V104.8a2.434,2.434,0,0,0,2.4,2.4h9.85a2.435,2.435,0,0,0,2.4-2.4V94.948a2.4,2.4,0,0,0-4.8,0V104.8l2.4-2.4h-9.85l2.4,2.4V94.948l-2.4,2.4h9.85a2.4,2.4,0,1,0,0-4.8Z" />
      <path d="M138.172,79.884H103a2.433,2.433,0,0,0-2.4,2.4v35.177a2.434,2.434,0,0,0,2.4,2.4h35.177a2.433,2.433,0,0,0,2.4-2.4q0-17.587,0-35.177c0-3.089-4.8-3.094-4.8,0q0,17.589,0,35.177l2.4-2.4H103l2.4,2.4V82.284l-2.4,2.4h35.177C141.261,84.684,141.266,79.884,138.172,79.884Z" />
      <path d="M125.508,94.948h-9.849V104.8h9.849Z" />
      <path d="M125.508,92.548h-9.849a2.434,2.434,0,0,0-2.4,2.4V104.8a2.434,2.434,0,0,0,2.4,2.4h9.849a2.434,2.434,0,0,0,2.4-2.4V94.948a2.4,2.4,0,0,0-4.8,0V104.8l2.4-2.4h-9.849l2.4,2.4V94.948l-2.4,2.4h9.849a2.4,2.4,0,1,0,0-4.8Z" />
      <path d="M113,157.714H103.66v4.2H113Z" />
      <path d="M113,155.314H103.66a2.435,2.435,0,0,0-2.4,2.4v4.2a2.435,2.435,0,0,0,2.4,2.4H113a2.435,2.435,0,0,0,2.4-2.4v-4.2a2.4,2.4,0,1,0-4.8,0v4.2l2.4-2.4H103.66l2.4,2.4v-4.2l-2.4,2.4H113a2.4,2.4,0,1,0,0-4.8Z" />
      <path d="M124.492,128.833v5.579" />
      <path d="M122.092,128.833v5.579a2.471,2.471,0,0,0,.7,1.7,2.4,2.4,0,0,0,4.1-1.7v-5.579a2.477,2.477,0,0,0-.7-1.7,2.4,2.4,0,0,0-4.1,1.7Z" />
      <path d="M136.935,128.833v11.4" />
      <path d="M134.535,128.833v11.4a2.4,2.4,0,0,0,4.8,0v-11.4a2.4,2.4,0,0,0-4.8,0Z" />
      <path d="M78.845,137.8H43.668a2.434,2.434,0,0,0-2.4,2.4v35.178a2.435,2.435,0,0,0,2.4,2.4H78.845a2.435,2.435,0,0,0,2.4-2.4q0-17.589,0-35.178c0-3.088-4.8-3.094-4.8,0q0,17.589,0,35.178l2.4-2.4H43.668l2.4,2.4V140.2l-2.4,2.4H78.845C81.934,142.6,81.939,137.8,78.845,137.8Z" />
      <path d="M66.182,152.861h-9.85v9.85h9.849C66.182,158.691,66.182,154.659,66.182,152.861Z" />
      <path d="M66.182,150.461h-9.85a2.434,2.434,0,0,0-2.4,2.4v9.85a2.435,2.435,0,0,0,2.4,2.4h9.849a2.435,2.435,0,0,0,2.4-2.4q0-4.926,0-9.85a2.4,2.4,0,0,0-4.8,0q0,4.925,0,9.85l2.4-2.4H56.332l2.4,2.4v-9.85l-2.4,2.4h9.85a2.4,2.4,0,1,0,0-4.8Z" />
      <path d="M109.359,220.87h-7.477v35.178h7.477Z" />
      <path d="M109.359,218.47q-3.738-.012-7.477,0a2.436,2.436,0,0,0-2.4,2.4v35.178a2.435,2.435,0,0,0,2.4,2.4h7.477a2.435,2.435,0,0,0,2.4-2.4V220.87c0-3.089-4.8-3.094-4.8,0v35.177l2.4-2.4h-7.477l2.4,2.4V220.869l-2.4,2.4q3.738-.011,7.477,0C112.448,223.279,112.452,218.479,109.359,218.47Z" />
      <path d="M92.093,220.87H88.152v35.178h3.941Z" />
      <path d="M92.093,218.47q-1.971-.006-3.941,0a2.436,2.436,0,0,0-2.4,2.4v35.178a2.435,2.435,0,0,0,2.4,2.4h3.941a2.435,2.435,0,0,0,2.4-2.4V220.87c0-3.089-4.8-3.094-4.8,0v35.177l2.4-2.4H88.152l2.4,2.4V220.869l-2.4,2.4q1.971,0,3.941,0C95.182,223.279,95.186,218.479,92.093,218.47Z" />
      <path d="M54.673,220.87H50.732v35.178h3.941Z" />
      <path d="M54.673,218.47q-1.971-.006-3.941,0a2.436,2.436,0,0,0-2.4,2.4v35.178a2.435,2.435,0,0,0,2.4,2.4h3.941a2.435,2.435,0,0,0,2.4-2.4V220.87c0-3.089-4.8-3.094-4.8,0v35.177l2.4-2.4H50.732l2.4,2.4V220.869l-2.4,2.4q1.97,0,3.941,0C57.761,223.279,57.766,218.479,54.673,218.47Z" />
      <path d="M63.845,220.869v35.178c0,3.089,4.8,3.094,4.8,0V220.869c0-3.088-4.8-3.093-4.8,0Z" />
      <path d="M75.213,220.869v35.178c0,3.089,4.8,3.094,4.8,0V220.869c0-3.088-4.8-3.093-4.8,0Z" />
      <path d="M117.6,220.869v35.178c0,3.089,4.8,3.094,4.8,0V220.869c0-3.088-4.8-3.093-4.8,0Z" />
      <path d="M128.8,220.869v35.178c0,3.089,4.8,3.094,4.8,0V220.869c0-3.088-4.8-3.093-4.8,0Z" />
      <path d="M140,220.869v35.178c0,3.089,4.8,3.094,4.8,0V220.869c0-3.088-4.8-3.093-4.8,0Z" />
      <path d="M37.043,220.869v35.178c0,3.089,4.8,3.094,4.8,0V220.869c0-3.088-4.8-3.093-4.8,0Z" />
      <path d="M34.956,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.974,3.089,2.517,3.089,1.58,0,2.518-1.2,2.518-3.089A2.767,2.767,0,0,0,34.956,267.48Z" />
      <path d="M41.548,267.48a2.768,2.768,0,0,0-2.517,3.032c0,1.892.973,3.089,2.517,3.089,1.579,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,41.548,267.48Z" />
      <path d="M48.139,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.974,3.089,2.517,3.089,1.58,0,2.518-1.2,2.518-3.089A2.766,2.766,0,0,0,48.139,267.48Z" />
      <path d="M54.731,267.48a2.768,2.768,0,0,0-2.517,3.032c0,1.892.973,3.089,2.517,3.089,1.58,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,54.731,267.48Z" />
      <path d="M65.674,267.48a2.768,2.768,0,0,0-2.517,3.032c0,1.892.973,3.089,2.517,3.089,1.579,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,65.674,267.48Z" />
      <path d="M72.265,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.974,3.089,2.517,3.089,1.58,0,2.518-1.2,2.518-3.089A2.766,2.766,0,0,0,72.265,267.48Z" />
      <path d="M78.857,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.973,3.089,2.517,3.089,1.58,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,78.857,267.48Z" />
      <path d="M85.448,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.974,3.089,2.518,3.089,1.579,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,85.448,267.48Z" />
      <path d="M96.391,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.974,3.089,2.517,3.089,1.58,0,2.518-1.2,2.518-3.089A2.766,2.766,0,0,0,96.391,267.48Z" />
      <path d="M102.983,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.973,3.089,2.517,3.089,1.58,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,102.983,267.48Z" />
      <path d="M109.574,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.974,3.089,2.518,3.089,1.579,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,109.574,267.48Z" />
      <path d="M116.166,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.973,3.089,2.517,3.089,1.58,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,116.166,267.48Z" />
      <path d="M127.109,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.973,3.089,2.517,3.089,1.58,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,127.109,267.48Z" />
      <path d="M133.7,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.974,3.089,2.518,3.089,1.579,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,133.7,267.48Z" />
      <path d="M140.292,267.48a2.769,2.769,0,0,0-2.517,3.032c0,1.892.973,3.089,2.517,3.089,1.58,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,140.292,267.48Z" />
      <path d="M146.884,267.48a2.769,2.769,0,0,0-2.518,3.032c0,1.892.974,3.089,2.518,3.089,1.579,0,2.517-1.2,2.517-3.089A2.766,2.766,0,0,0,146.884,267.48Z" />
    </svg>
  );
};
