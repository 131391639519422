import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/Firebase";
import { StripeResponse } from "../../../types";
import { generateErrorFirebaseAndAxiosErrors } from "../../../api/helper";

export const GetStripeDetails = async (checkoutId: string) => {
  const getStatusFunction = httpsCallable(functions, "StripeGetStatus");
  try {
    const result = await getStatusFunction({
      orderId: checkoutId,
    });
    return result.data as StripeResponse;
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors("決済の確認に失敗しました。", e);
  }
};
