import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/Firebase";
import { PayPayDetailsResponse } from "../../../types";
import { generateErrorFirebaseAndAxiosErrors } from "../../../api/helper";

export const GetPayPayDetails = async (orderId: string) => {
  const getStatusFunction = httpsCallable(functions, "PayPayGetStatus");
  try {
    const result = await getStatusFunction({
      orderId: orderId,
    });
    return result.data as PayPayDetailsResponse;
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors("決済の確認に失敗しました。", e);
  }
};