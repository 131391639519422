import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { RunTicket } from "../../../assets/icons/RunTicket";

const settings = ["ログアウト"];
const toLink = ["/logout"];
interface AppBarProps {
  photoURL: string;
  onClick: () => void;
}

export const AppBar = (props: AppBarProps) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  // aapBerの高さを取得, これを使ってスペーサーを設定するためのdisable
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appBar = useRef<any>(null);

  return (
    <MuiAppBar
      ref={appBar}
      position="sticky"
      className="bg-runticketBlue"
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters className="mx-auto max-w-[800px]">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={props.onClick}
              color="default"
            >
              <MenuIcon className="text-white" />
            </IconButton>
          </Box>
          <Button
            onClick={() => {
              window.location.href = "/";
            }}
            className="absolute left-1/2 max-w-[260px] -translate-x-1/2 text-2xl text-white"
          >
            <RunTicket className="w-48" fill="#fff" />
          </Button>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={props.onClick}
              color="default"
            >
              <MenuIcon className="text-white" />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="user icon" src={props.photoURL} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => {
                setAnchorElUser(null);
              }}
            >
              {settings.map((setting, index: number) => (
                <MenuItem
                  key={setting}
                  component={Link}
                  to={toLink[index]}
                  onClick={() => {
                    setAnchorElUser(null);
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
};
