import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties | undefined;
  className?: string;
  fill?: string;
}

export const RunTicket = ({ style, className, fill }: Props) => {
  return (
    <svg
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 1119.00 223.00"
      fill={fill ?? "#ffffff"}
    >
      <path
        d="
  M 52.19 120.52
  Q 52.03 145.52 52.05 170.52
  Q 52.06 174.62 50.78 176.71
  C 47.10 182.74 38.34 182.02 35.33 175.67
  Q 34.53 173.99 34.53 170.95
  Q 34.46 142.26 34.52 113.56
  C 34.54 106.32 37.10 102.37 44.52 102.38
  Q 69.80 102.40 95.07 102.34
  C 126.29 102.26 133.85 60.70 104.43 51.05
  Q 100.52 49.77 94.16 49.76
  Q 70.21 49.73 46.26 49.77
  Q 41.59 49.78 39.41 48.71
  C 30.87 44.52 34.34 32.28 43.82 32.26
  Q 67.40 32.20 90.98 32.24
  Q 102.35 32.26 107.87 33.72
  C 129.83 39.50 143.71 61.12 139.75 83.71
  C 136.14 104.31 118.28 119.56 97.00 119.73
  Q 74.86 119.91 52.72 119.99
  Q 52.19 119.99 52.19 120.52
  Z"
      />
      <rect x="433.56" y="32.23" width="103.32" height="17.50" rx="8.60" />
      <circle cx="577.71" cy="44.27" r="12.02" />
      <rect x="769.33" y="32.27" width="17.60" height="148.56" rx="8.68" />
      <path
        d="
  M 1054.27 85.78
  Q 1054.21 111.64 1054.25 137.50
  Q 1054.27 153.55 1067.80 162.08
  C 1070.32 163.67 1073.39 164.55 1076.38 165.69
  C 1083.34 168.35 1082.99 180.20 1076.02 182.18
  Q 1073.48 182.90 1069.04 181.66
  C 1053.15 177.20 1041.53 163.82 1037.97 148.53
  Q 1036.62 142.69 1036.62 128.63
  Q 1036.63 86.38 1036.62 44.14
  Q 1036.62 38.65 1037.54 36.74
  C 1040.16 31.29 1049.63 31.03 1052.84 35.92
  Q 1054.27 38.09 1054.26 43.35
  Q 1054.22 56.17 1054.26 68.98
  Q 1054.26 69.50 1054.79 69.51
  Q 1062.91 69.65 1070.96 69.55
  Q 1074.94 69.50 1076.86 70.42
  C 1085.00 74.30 1081.23 85.32 1072.37 85.18
  Q 1063.61 85.04 1054.83 85.21
  Q 1054.28 85.22 1054.27 85.78
  Z"
      />
      <path
        d="
  M 401.52 108.11
  C 397.82 93.66 383.20 82.54 367.82 84.05
  C 354.50 85.36 343.70 94.76 339.87 107.55
  Q 338.51 112.07 338.48 120.15
  Q 338.37 144.83 338.42 169.53
  Q 338.43 173.81 337.49 175.86
  C 334.41 182.59 324.56 182.50 321.61 175.66
  Q 320.86 173.92 320.86 170.49
  Q 320.89 147.86 320.87 125.25
  Q 320.85 111.44 322.23 105.35
  C 328.07 79.70 353.70 62.32 379.55 67.34
  C 399.36 71.19 414.32 86.09 418.98 105.70
  Q 420.36 111.50 420.39 121.91
  Q 420.47 145.61 420.41 169.29
  Q 420.40 173.75 419.47 175.81
  C 416.38 182.65 406.12 182.59 403.45 175.36
  Q 402.82 173.68 402.82 170.06
  Q 402.77 149.03 402.80 128.01
  Q 402.82 113.16 401.52 108.11
  Z"
      />
      <path
        d="
  M 667.29 161.94
  C 679.54 167.66 696.19 166.47 707.11 157.12
  C 710.22 154.45 714.28 148.48 718.43 147.17
  C 723.95 145.42 730.25 150.35 729.63 156.21
  Q 729.35 158.94 726.67 162.22
  C 711.47 180.88 685.87 187.94 663.60 179.43
  C 644.57 172.16 631.14 155.69 627.41 135.67
  C 617.25 81.09 681.79 42.60 723.13 83.39
  C 726.10 86.32 729.61 89.94 729.64 94.14
  C 729.68 100.40 722.38 104.52 716.93 101.77
  C 713.78 100.18 711.96 96.72 709.27 94.31
  C 689.42 76.55 659.37 82.70 648.08 106.68
  C 638.40 127.26 646.40 152.20 667.29 161.94
  Z"
      />
      <path
        d="
  M 812.68 119.89
  Q 812.43 120.16 812.69 120.42
  Q 834.23 142.83 855.65 165.40
  Q 858.20 168.09 858.64 170.42
  C 859.94 177.20 853.53 182.69 847.11 180.36
  Q 844.95 179.57 841.92 176.38
  Q 819.33 152.66 796.66 129.04
  C 791.76 123.93 789.21 119.37 794.56 113.55
  Q 813.76 92.67 832.86 71.68
  Q 836.53 67.64 839.27 66.72
  C 845.16 64.73 852.08 71.29 850.10 77.40
  Q 849.43 79.48 846.14 83.15
  Q 829.50 101.66 812.68 119.89
  Z"
      />
      <path
        d="
  M 903.91 133.18
  C 910.66 162.75 944.07 176.24 967.93 155.70
  C 969.58 154.28 971.18 152.33 972.82 150.58
  C 980.45 142.44 993.39 153.46 985.15 162.95
  Q 976.62 172.78 965.63 177.89
  C 931.10 193.99 892.27 170.88 886.40 134.70
  C 880.29 97.08 910.28 62.39 948.96 66.88
  C 976.60 70.08 998.72 94.32 999.13 122.61
  C 999.23 129.46 996.42 132.62 989.50 132.53
  Q 968.95 132.25 904.36 132.61
  Q 903.78 132.61 903.91 133.18
  Z
  M 903.80 116.11
  A 0.73 0.72 -82.6 0 0 904.50 117.02
  L 980.26 117.03
  Q 980.89 117.03 980.79 116.40
  C 976.29 89.25 943.87 74.93 921.32 90.32
  Q 908.26 99.23 903.80 116.11
  Z"
      />
      <rect x="476.48" y="67.53" width="17.72" height="113.32" rx="8.71" />
      <path
        d="
  M 251.88 156.02
  C 258.98 148.99 262.16 140.05 262.20 130.02
  Q 262.29 105.91 262.23 81.79
  Q 262.22 77.26 263.55 74.95
  C 266.50 69.84 275.17 69.50 278.44 74.72
  Q 279.77 76.84 279.77 81.06
  Q 279.75 100.78 279.80 120.51
  Q 279.84 134.78 278.82 140.45
  C 275.27 160.32 260.69 176.89 240.89 181.58
  C 207.78 189.42 178.30 163.61 178.24 130.25
  Q 178.19 106.54 178.24 82.83
  Q 178.25 78.28 179.05 76.29
  C 181.96 68.97 192.89 69.22 195.38 76.73
  Q 195.93 78.39 195.92 82.33
  Q 195.84 104.31 195.92 126.29
  Q 195.96 136.60 197.43 141.58
  C 204.37 165.07 234.06 173.70 251.88 156.02
  Z"
      />
      <rect
        x="-8.72"
        y="-54.92"
        transform="translate(577.71,125.91) rotate(0.1)"
        width="17.44"
        height="109.84"
        rx="8.57"
      />
      <rect
        x="-8.47"
        y="-26.60"
        transform="translate(118.87,156.70) rotate(-30.7)"
        width="16.94"
        height="53.20"
        rx="8.26"
      />
    </svg>
  );
};
