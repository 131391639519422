import { Avatar, Button, Box } from "@mui/material";
import { User } from "firebase/auth";
import { Link } from "react-router-dom";
import { ScrollDialog } from ".";
import { useState } from "react";

interface LoginBoxProps {
  user: User | undefined;
  login: () => void;
  logout: () => void;
}

export const LoginBox = ({ user, login, logout }: LoginBoxProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box
      component="form"
      onSubmit={login}
      noValidate
      sx={{ my: 12, mx: 5 }}
      className="flex flex-col items-center justify-center"
    >
      <Avatar src={user?.photoURL ?? ""} alt="logo" sx={{ mx: 17 }} />
      {user ? (
        <>
          <h2 className="text-center">
            お帰りなさい{user?.displayName || ""}さん
          </h2>
          <Button
            className="bg-runticketBlue"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            component={Link}
            to="/"
          >
            メニューを見る
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            color="error"
            onClick={() => {
              logout();
            }}
          >
            ログアウト
          </Button>
        </>
      ) : (
        <>
          <h2 className="text-center">
            学生のGoogleアカウントでサインインしてください
          </h2>
          <Button
            className="bg-runticketBlue"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            ログイン
          </Button>
        </>
      )}
      <ScrollDialog
        open={open}
        onClick={function (): void {
          login();
        }}
        setIsClose={setOpen}
      />
    </Box>
  );
};
