import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingAnimation } from "../../../components/LoadingAnimation";

export const Logout = ({ logout }: { logout: () => void }) => {
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    navigate("/");
  }, [logout, navigate]);
  return <LoadingAnimation type="jelly" />;
};
