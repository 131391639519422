import { CorrectEmail } from "../features/auth/utils";
import { IllegalEmailAddress, LoginBox } from "../features/auth/components";
import { User } from "firebase/auth";

export const Register = ({
  user,
  login,
  logout,
  deleteAccount,
}: {
  user?: User;
  login: () => void;
  logout: () => void;
  deleteAccount: () => void;
}) => {
  if (user && !CorrectEmail(user.email)) {
    return (
      <IllegalEmailAddress
        email={user.email ?? ""}
        onClick={() => {
          deleteAccount();
          login();
        }}
      />
    );
  } else {
    return <LoginBox user={user} login={login} logout={logout} />;
  }
};
