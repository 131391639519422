import { useState } from "react";
import { AppBar, DrawerLeft } from "./components";

export const Header = ({ photoURL }: { photoURL: string }) => {
  const [isMenu, setIsMenu] = useState<boolean>(false);

  return (
    <>
      <DrawerLeft isMenuOpen={isMenu} setIsMenu={setIsMenu} />
      <AppBar
        photoURL={photoURL}
        onClick={() => {
          setIsMenu(!isMenu);
        }}
      />
    </>
  );
};
