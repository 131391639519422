import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../../config/Firebase";
import { DocumentData, doc, updateDoc } from "firebase/firestore";
import { generateErrorFirebaseAndAxiosErrors } from "../../../api/helper";

export const AssignOrderNumber = async (orderId: string) => {
  const addCount = httpsCallable(functions, "addCount");
  try {
    const result: DocumentData = await addCount();
    const docId = doc(db, "order", orderId);
    await updateDoc(docId, {
      orderNumber: result.data,
    });
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "注文番号の付与に失敗しました。",
      e
    );
  }
};
