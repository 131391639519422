import { Button } from "@mui/material";
import { TestOnCallable } from "./callable.test";

export const TestData = () => {
  return (
    <>
      <Button variant="contained" onClick={TestOnCallable}>
        TEST
      </Button>
    </>
  );
};
