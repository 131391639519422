import axios from "axios";
import qs from "qs";

export const SendMessageDiscord = async (email: string | null) => {
  if (!email) return;
  const data = {
    email: email,
  };
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  await axios.post(
    "https://script.google.com/macros/s/AKfycbzMemXiN7Z-6LZAk4-4KJ1I6_tnZu1nTo5mZj6ouKP1xOb9eNeBNOGeUS_XQyj5bYXV/exec",
    qs.stringify(data),
    options
  );
};