import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties | undefined;
  className?: string;
}

export const Scan = ({ style, className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="256.549"
      height="334.851"
      viewBox="0 0 256.549 334.851"
      style={style}
      className={className}
    >
      <path
        style={{ fill: "#211715" }}
        d="M213.387,5.338V2.413l-2.4,2.4q8.753,0,17.507-.013l-2.4-2.4V5.338c0,3.089,4.8,3.094,4.8,0V2.4a2.435,2.435,0,0,0-2.4-2.4q-8.754,0-17.507.013a2.435,2.435,0,0,0-2.4,2.4V5.338c0,3.089,4.8,3.094,4.8,0Z"
      />
      <path
        style={{ fill: "#211715" }}
        d="M250.643,34.62h3.493l-2.4-2.4q0,8.754.013,17.507l2.4-2.4h-3.506c-3.088,0-3.093,4.8,0,4.8h3.506a2.435,2.435,0,0,0,2.4-2.4q0-8.754-.013-17.507a2.435,2.435,0,0,0-2.4-2.4h-3.493c-3.088,0-3.093,4.8,0,4.8Z"
      />
      <path
        style={{ fill: "#211715" }}
        d="M250.643,65.623h3.493l-2.4-2.4q0,8.753.013,17.507l2.4-2.4h-3.506c-3.088,0-3.093,4.8,0,4.8h3.506a2.435,2.435,0,0,0,2.4-2.4q0-8.754-.013-17.507a2.435,2.435,0,0,0-2.4-2.4h-3.493c-3.088,0-3.093,4.8,0,4.8Z"
      />
      <path
        style={{ fill: "#211715" }}
        d="M253.043,324.384V14.594a17.934,17.934,0,0,0-.2-3.235c-.921-5.14-5.6-8.393-10.626-8.421-3.563-.019-7.127,0-10.691,0H19.036c-2.858,0-5.723-.059-8.58,0A10.572,10.572,0,0,0,.007,13.415c-.015.708,0,1.418,0,2.126v304.7c0,4.11-.254,8.038,2.9,11.36,2.748,2.9,6.295,3.2,10.035,3.2h68.24l49.514,0,49.039,0,42.1,0,15.851,0c2.67,0,5.663.341,8.219-.523a10.588,10.588,0,0,0,7.145-9.878c.087-3.09-4.714-3.088-4.8,0a5.718,5.718,0,0,1-2.29,4.531,7.2,7.2,0,0,1-4.582,1.069l-7.257,0-17.177,0-42.753,0-48.992,0-48.763,0H17.562c-2.275,0-4.551.018-6.826,0a5.7,5.7,0,0,1-5.929-5.607c-.035-1.973,0-3.95,0-5.923V15.1c0-.582-.011-1.165,0-1.746.059-3.414,2.782-5.6,6.039-5.62,3.533-.016,7.067,0,10.6,0H232.771c3.218,0,6.437-.03,9.654,0a5.7,5.7,0,0,1,5.818,5.7c.011.712,0,1.426,0,2.138V324.384C248.243,327.472,253.043,327.478,253.043,324.384Z"
      />
    </svg>
  );
};
