import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HistoryIcon from "@mui/icons-material/History";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { Link } from "react-router-dom";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

interface navigationItemPathTypes {
  name: string;
  path: string;
}

const navigationItem: navigationItemPathTypes[] = [
  {
    name: "メニュー",
    path: "/",
  },
  {
    name: "注文履歴",
    path: "/history",
  },
  {
    name: "divider",
    path: "",
  },
  {
    name: "ヘルプ",
    path: "/help",
  },
  {
    name: "利用規約",
    path: "/terms",
  },
  {
    name: "プライバシーポリシー",
    path: "/privacy",
  },
  {
    name: "お問い合わせ",
    path: "https://docs.google.com/forms/d/e/1FAIpQLSfRRIK0WBAoMt_WN3RAKbP598LZOQAhsOrIQu8O7eAZE81x1Q/viewform",
  },
];
interface DrawerLeftProps {
  isMenuOpen: boolean;
  setIsMenu: (isMenuOpen: boolean) => void;
}

export const DrawerLeft = ({ isMenuOpen, setIsMenu }: DrawerLeftProps) => {
  return (
    <Drawer
      anchor="left"
      open={isMenuOpen}
      onClose={() => {
        setIsMenu(false);
      }}
    >
      <List>
        {navigationItem.map((domain, index) => (
          <div key={index}>
            {domain.name === "divider" ? (
              <Divider />
            ) : (
              <ListItem disablePadding>
                <Link
                  className="w-full"
                  to={domain.name === "お問い合わせ" ? "" : domain.path}
                  onClick={() => {
                    setIsMenu(false);
                    if (domain.name === "お問い合わせ")
                      window.location.href = domain.path;
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {domain.name === "メニュー" && <RestaurantMenuIcon />}
                      {domain.name === "注文履歴" && <HistoryIcon />}
                      {domain.name === "ヘルプ" && <HelpOutlineIcon />}
                      {domain.name === "利用規約" && <InboxIcon />}
                      {domain.name === "プライバシーポリシー" && (
                        <PrivacyTipIcon />
                      )}
                      {domain.name === "お問い合わせ" && <ContactPageIcon />}
                    </ListItemIcon>
                    <ListItemText primary={domain.name} />
                  </ListItemButton>
                </Link>
              </ListItem>
            )}
          </div>
        ))}
      </List>
    </Drawer>
  );
};
