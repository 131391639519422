import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperCore } from "swiper";
import "swiper/css";
import { useRef, useState } from "react";
import { SwipeableMenuProps } from "./types";
import { FilterMenu } from "./components";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function SwipeableMenu(props: SwipeableMenuProps) {
  const menuCategoryArray: string[] = props.category;
  const [value, setValue] = useState(0);
  const swiperRef = useRef<SwiperCore | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    swiperRef.current?.slideTo(newValue);
  };
  const onSwiper = (currentSwiper: SwiperCore) => {
    const swiperInstance = currentSwiper;
    swiperRef.current = swiperInstance;
  };
  const onSlideChange = (currentSwiper: SwiperCore) => {
    setValue(currentSwiper.activeIndex);
  };

  return (
    <div>
      <Tabs
        className="pointer-events-auto bg-white text-white"
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        {menuCategoryArray.map((e, i) => {
          return <Tab key={i} label={e} {...a11yProps(i)} />;
        })}
      </Tabs>
      <Swiper
        simulateTouch={false}
        spaceBetween={50}
        slidesPerView={1}
        onSwiper={onSwiper}
        onSlideChange={onSlideChange}
      >
        {menuCategoryArray.map((category: string, index: number) => {
          return (
            <SwiperSlide key={index}>
              <TabPanel value={value} index={index}>
                <FilterMenu
                  menu={props.menu}
                  categoryMode={category}
                  setChosenMenu={props.setChosenMenu}
                  setDetailDialogOpen={props.setDetailDialogOpen}
                  category={props.category}
                />
              </TabPanel>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
