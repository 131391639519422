import { httpsCallable } from "firebase/functions";
import { functions } from "../config/Firebase";
import { generateErrorFirebaseAndAxiosErrors } from "../api/helper";

export const TestOnCallable = async () => {
  const getStatusFunction = httpsCallable(functions, "MQTTPublish");
  try {
    await getStatusFunction({
      id: "ndkajbfkjasbf",
      title: "唐揚げ定食",
      date: "12/20",
      time: "12:20:21",
      status: "send",
    });
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors("発券に失敗しました", e);
  }
};
