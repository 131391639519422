import { useMemo } from "react";
import { SwipeableMenuProps } from "../types";
import { MenuData } from "../../../../types";
import { Grid } from "@mui/material";
import { FoodCard } from ".";

interface FilterMenuDataProps extends SwipeableMenuProps {
  categoryMode: string;
}

export const FilterMenu = (props: FilterMenuDataProps) => {
  const filteredMenu = useMemo(() => {
    return props.menu.filter(
      (item: MenuData) => item.category === props.categoryMode
    );
  }, [props.categoryMode, props.menu]);
  return (
    <div className="menu_box">
      <Grid
        padding={1.5}
        container
        spacing={1.5}
        justifyContent="start"
        className="mb-16"
      >
        {filteredMenu.map((menu: MenuData, index: number) => {
          return (
            <Grid item key={index} xs={6} sm={4}>
              <FoodCard
                menu={menu}
                deleteButton={false}
                onClick={function (): void {
                  props.setChosenMenu(menu);
                  props.setDetailDialogOpen(true);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
