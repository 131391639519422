import { GoogleAuthProvider, User, signInWithPopup } from "firebase/auth";
import { auth } from "../../../config/Firebase";
const provider = new GoogleAuthProvider();

export const LoginPopup = ({ setUser }: { setUser: (user: User) => void; }) => {
  signInWithPopup(auth, provider)
    .then(async (result) => {
      const user = result.user;
      // await addUser(user.email);
      setUser(user);
    })
    .catch((error) => {
      console.log(error);
    });
};