interface Props {
  constant: number;
  text: string;
  className: string;
}
export const TextLengthFitSize = ({ text, constant, className }: Props) => {
  const textSizePercentage = constant - text.length;
  const textStyle = {
    fontSize: `${textSizePercentage}%`,
  };

  return (
    <div className={className} style={textStyle}>
      {text}
    </div>
  );
};
