import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../config/Firebase";
import { OrderData } from "../../../types";
import { generateErrorFirebaseAndAxiosErrors } from "../../../api/helper";

export const FetchOneOrderDocument = async (collectionId: string) => {
  try {
    const docRef = doc(db, "order", collectionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      throw "注文情報が存在しません。";
    }
    return docSnap.data() as OrderData;
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "注文情報の取得に失敗しました。",
      e
    );
  }
};